import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import illustration from '../../../resources/lottie-animations/tokens.json';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnimatedIllustration = makeShortcode("AnimatedIllustration");
const InternalContent = makeShortcode("InternalContent");
const Do = makeShortcode("Do");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimatedIllustration data={illustration} css={{
      marginBottom: 32
    }} mdxType="AnimatedIllustration" />
    <h1 {...{
      "id": "tokens-explained"
    }}>{`Tokens Explained`}</h1>
    <p>{`Tokens are the smallest pieces of our Design System with the primary function of storing UI information. They enable building components and patterns with consistency across our platforms.`}</p>
    <p>{`Everything created in Canvas uses tokens, which represent preset values like hex values or rem. They are like the DNA of the Workday product family. So it’s important to utilize tokens instead of hardcoded values when creating and building with Canvas. It will make the job of the designer, engineer and quality assurance person much easier by removing room for error as things are designed and built.`}</p>
    <p>{`Certain tokens are semantic and named for their intended purpose (ex: `}<inlineCode parentName="p">{`color.bg.status.success`}</inlineCode>{` sets a background color for success elements). This allows the application of tokens to be consistent, even if the core value they point to, like a hex code, changes.`}</p>
    <h2 {...{
      "id": "benefits-of-canvas-tokens"
    }}>{`Benefits Of Canvas Tokens`}</h2>
    <ul>
      <li parentName="ul">{`Enable scalable visual changes at the core token level that flows across all Canvas components`}</li>
      <li parentName="ul">{`Increase cohesion across Canvas components by standardizing token usage with semantic tokens`}</li>
      <li parentName="ul">{`Empower product teams to build custom components using semantic tokens that can evolve with Canvas tokens`}</li>
    </ul>
    <p>{`Tokens make decision making much easier and the output more accurate. When designing and building, always use Canvas Tokens to inform the design decisions you make, such as:`}</p>
    <InternalContent mdxType="InternalContent">
      <Do hideHeading={true} mdxType="Do">
        <ul>
          <li parentName="ul"><strong parentName="li">{`Color`}</strong>{` - Use the preset Workday `}<a parentName="li" {...{
              "href": "/styles/tokens/color"
            }}>{`color palette`}</a>{` in Figma and Canvas Kit`}</li>
          <li parentName="ul"><strong parentName="li">{`Depth`}</strong>{` - Use the preset `}<a parentName="li" {...{
              "href": "/styles/tokens/depth"
            }}>{`depth tokens`}</a>{` in Figma and Canvas Kit for drop shadows`}</li>
          <li parentName="ul"><strong parentName="li">{`Shape`}</strong>{` - Use the preset `}<a parentName="li" {...{
              "href": "/styles/tokens/shape"
            }}>{`shape tokens`}</a>{` for border radius`}</li>
          <li parentName="ul"><strong parentName="li">{`Space`}</strong>{` - Spacing should align with the preset 8pt grid `}<a parentName="li" {...{
              "href": "/styles/tokens/space"
            }}>{`spacing tokens`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Type`}</strong>{` - The `}<a parentName="li" {...{
              "href": "/styles/tokens/type"
            }}>{`type styles`}</a>{` you use to ensure clear and consistent hierarchy`}</li>
        </ul>
      </Do>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <Do hideHeading={true} mdxType="Do">
        <ul>
          <li parentName="ul"><strong parentName="li">{`Color`}</strong>{` - Use the preset Workday `}<a parentName="li" {...{
              "href": "/styles/tokens/color"
            }}>{`color palette`}</a>{` in Canvas Kit`}</li>
          <li parentName="ul"><strong parentName="li">{`Depth`}</strong>{` - Use the preset `}<a parentName="li" {...{
              "href": "/styles/tokens/depth"
            }}>{`depth tokens`}</a>{` in Canvas Kit for drop shadows`}</li>
          <li parentName="ul"><strong parentName="li">{`Shape`}</strong>{` - Use the preset `}<a parentName="li" {...{
              "href": "/styles/tokens/shape"
            }}>{`shape tokens`}</a>{` for border radius`}</li>
          <li parentName="ul"><strong parentName="li">{`Space`}</strong>{` - Spacing should align with the preset 8pt grid `}<a parentName="li" {...{
              "href": "/styles/tokens/space"
            }}>{`spacing tokens`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Type`}</strong>{` - The `}<a parentName="li" {...{
              "href": "/styles/tokens/type"
            }}>{`type styles`}</a>{` you use to ensure clear and consistent hierarchy`}</li>
        </ul>
      </Do>
    </ExternalContent>
    <h2 {...{
      "id": "token-structure"
    }}>{`Token Structure`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7490bc01a598ee6241caa4d61d423920/60b3a/token-structure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.67567567567568%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6UlEQVR42o2SS08TURSA+Se6JfUVoZSXndIXfWpjqLBp1YUa/4DGRHds/COsNOKj8hARSYiIoCYsjIkb0QWtfc2r02ln5nPmlkrcMcnNmbkz55vvnHMHOMHlOI6IzabMs5dL/63npRWeLJbY3NoW3wz0E+qNOuVKhXq9jmma4mW1WuOwXHZjVTx/+LiLzx9CSlxlLJLlwniUwaEgp30jZPJFjHa7B7RsqDRUdE3Ftm2R3LG6VGp/0DSdTrcr9rY/7TEZzxHNzhKYSjMSSrkxxdnRCNeKdzAMD+jaHVYb/KwoKIpMU5ZFaZrugTr0fmgfAT8jxS8TimeYmEowEU4gRVNcHA8zU7jVA3rl1mp12mYH2+mV7y3Lsugemdn/gHuEkzkiiSzBWAoplhbw82MR8sXbx4ZNWUVrtd1Mk5ZhCEDXLVk3WjhuP1RNE8Cd3S9uDyXOBUIMTcbwB6cJhJKcCYTJ9w1dJ2RV5+uByv4vRdw7LtC0OuwffOP77x/IitIz3HEN03nmbt7l0nSO4WCS0bBnGGX2utdDoz8UhwevmhQW3EmrlkjWTYOHi4+593QeudUDrr/f4v6jed5ubHJl7ganBv34hiURMzOF4yk7JzyHnmlpeY0Xr1dZWl1n+c07VtY2xJ5n753DvyHJR3wIN+/kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of three primary button example showing how base, system, and brand tokens are applied.",
            "title": "Image of three primary button example showing how base, system, and brand tokens are applied.",
            "src": "/static/7490bc01a598ee6241caa4d61d423920/50383/token-structure.png",
            "srcSet": ["/static/7490bc01a598ee6241caa4d61d423920/1efb2/token-structure.png 370w", "/static/7490bc01a598ee6241caa4d61d423920/50383/token-structure.png 740w", "/static/7490bc01a598ee6241caa4d61d423920/60b3a/token-structure.png 1179w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`There are three level of Canvas tokens, which includes:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Base Tokens:`}</strong>{` tokens that represent hard coded values like a hex code or rem value. These tokens are maintained by the Canvas Design System team and should rarely be used by product teams.`}</li>
      <li parentName="ol"><strong parentName="li">{`System Tokens:`}</strong>{` tokens that are intended to be used by product teams. They are semantic, meaning that they are named according to how they should be used (ex: `}<inlineCode parentName="li">{`color.bg.status.success`}</inlineCode>{` sets a background color for success elements). These tokens are the most similar to what is available in Canvas today (ex: type, depth).`}</li>
      <li parentName="ol"><strong parentName="li">{`Brand Tokens:`}</strong>{` tokens that are used to set key brand colors and can be used by tenants to theme certain components to reflect their brand color. `}</li>
    </ol>
    <p>{`Canvas tokens use rem as the standard unit of measurement, except for color tokens which are represented as hex values.`}</p>
    <h3 {...{
      "id": "token-naming-convention"
    }}>{`Token Naming Convention`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/582374660bc3c0e8e381632de6500576/baaa6/token-naming-convention.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.945945945945947%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnklEQVR42jWOiwqCQBBF+//PqxBTMy3yseGqKO5D15OtOXC5A3O4c0/8x9oZbSxaG++T0iitMcZQdwq7rMzLzpgfu3HKSyN6zeJWn3Na130pa8H5GhAnKeEtpqwEaZaT5Tlx0WMcSCm5BCFBGBEld7Lna/OUh5j8Qx94NByGkXdRINsWIT445+j63usYpdTGlBRlRdNIhnGkka2/HcW+ZNLlwiDrJiYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of a diagram showing how the system token name is structured.",
            "title": "Image of a diagram showing how the system token name is structured.",
            "src": "/static/582374660bc3c0e8e381632de6500576/50383/token-naming-convention.png",
            "srcSet": ["/static/582374660bc3c0e8e381632de6500576/1efb2/token-naming-convention.png 370w", "/static/582374660bc3c0e8e381632de6500576/50383/token-naming-convention.png 740w", "/static/582374660bc3c0e8e381632de6500576/baaa6/token-naming-convention.png 813w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Canvas tokens follow a standard naming convention that contains four core parts:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Namespace:`}</strong>{` used to indicate where the token is coming from, like Canvas or another design system / product team`}</li>
      <li parentName="ol"><strong parentName="li">{`Level:`}</strong>{` used to indicate the token level (ex: base, system)`}</li>
      <li parentName="ol"><strong parentName="li">{`Property:`}</strong>{` used to indicate the type, concept, or category of the token (ex: color, type, shape)`}</li>
      <li parentName="ol"><strong parentName="li">{`Modifier:`}</strong>{` used to indicate the variant, state, or mode of the token (ex: lightest, hover)`}</li>
    </ol>
    <h3 {...{
      "id": "base-tokens"
    }}>{`Base Tokens`}</h3>
    <p>{`Base tokens are tokens that are tied to hard coded values like a hex code or rem value. These tokens are maintained by the Canas Design System team and are subject to change. Base tokens include: color, letter spacing, font size, box shadow, etc. These tokens are used by the Canvas Design System team to build and support system level tokens.`}</p>
    <h4 {...{
      "id": "base-color-token-example"
    }}>{`Base Color Token Example`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d90d27e4acd47b58d59d72eb75bc3096/024d6/base-token.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.08108108108108%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVR42o1SCW7DIBDk/5/rE1qljrlswMGpuSeAK0tt3CorDSux1+wAKQBmbSClREoJr5i1FkoplFKeYqRdMX2H0ho555caeu8RQjiNkTZl5BJCiJ7YWMaK/9gqrcAYh/PPTUmuFN+5hZwVjDHQeodd179XrrFZGfiYzxm67Qs+eMQYfwTPNGq2tXzX8k8ZFlzohJFSyGmCc64WbN03TUMd0hEi0rfGSmkwyiAm1eWJMR3DSar+7WPBMPLalGG4jrgMV3xWLDcLLiQYF6BVs3W996JlsZ0Ab49pbvWHTMd2pB0x5c4mV0F35ANn1tiUvOP3yg8sfXLBlZHHXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of three primary button example showing how base, system, and brand tokens are applied.",
            "title": "Image of three primary button example showing how base, system, and brand tokens are applied.",
            "src": "/static/d90d27e4acd47b58d59d72eb75bc3096/50383/base-token.png",
            "srcSet": ["/static/d90d27e4acd47b58d59d72eb75bc3096/1efb2/base-token.png 370w", "/static/d90d27e4acd47b58d59d72eb75bc3096/50383/base-token.png 740w", "/static/d90d27e4acd47b58d59d72eb75bc3096/024d6/base-token.png 961w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "system-tokens"
    }}>{`System Tokens`}</h3>
    <p>{`System tokens exist one layer above of base level tokens. They can alias base tokens, but are meant to be more semantic and contextual for their intended use cases. These tokens are the most similar to what is available in Canvas today and include the following types: color, type, depth, shape, space, opacity `}</p>
    <p>{`Product teams should use system tokens when customizing Canvas components or building their custom components.`}</p>
    <h4 {...{
      "id": "system-color-token-example"
    }}>{`System Color Token Example`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/714983623c76b081addcfb7e93dd2059/3534c/system-color-token.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.270270270270274%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR42n2R6w6DIAyFff/nnJnOG+gYN+GsLZDoj+0kxzTYfuVoB1JKCdY5OO/FXL+NEbMyiuJ5wtrSZ63Fh2zMByfNN3X8OKlxUxrq4mEY0fe9LMu5IBmwrBv0fkDpHdum8OifsuAG/CW905BSCCHIjXjxNU2MUc6vugE5Ejd6Argam2sBkFNO8AyivgZvCVqKG5C/B0fi+POyUixNQC+QECL245DzaV6qZ4yvSWpfY/+NfJVEpR/C5mFXb11u7+U9A7+NRdWex1yaQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of Text Input component showing which system color tokens are applied to each element.",
            "title": "Image of Text Input component showing which system color tokens are applied to each element.",
            "src": "/static/714983623c76b081addcfb7e93dd2059/50383/system-color-token.png",
            "srcSet": ["/static/714983623c76b081addcfb7e93dd2059/1efb2/system-color-token.png 370w", "/static/714983623c76b081addcfb7e93dd2059/50383/system-color-token.png 740w", "/static/714983623c76b081addcfb7e93dd2059/3534c/system-color-token.png 808w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Note: Figma Color Styles are used for color tokens.`}</em></p>
    <h4 {...{
      "id": "system-type-token-example"
    }}>{`System Type Token Example`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8e5b3f0e4b89c02f156f5edee1d533ba/60b3a/system-type-token.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.567567567567565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA70lEQVR42q2R627DIAyF8/7vODUNy6LmRriEO5watB9bN22qVEuWuZjDZ7vDi6377dB7j0MI7DvHcQiEEJBz/pFXPv1fwVIKSsoIxkEqBSVUi9Y5GGORS/mbMIQIISSU0lBa4zQG+7piHBim9xHs7QI2DDBSQ2w7pFTQlCe0AT8Tvuo3Qed8K6+K1hKbOHkV5esGvqwQB0eyDtl6zLS/zTOmZQfb/DfirtLV3+qv52kaZY0LPegvlYzhSnTXvscH0SYf6P5sbxQRCvNAWPsVY0Qgjym1dc4FkgincQTnHJb6Zq2l/hlkynl6ys/Y45TvnvRyAYqCtWUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of Text sample showing which system type tokens are applied to each text element.",
            "title": "Image of Text sample showing which system type tokens are applied to each text element.",
            "src": "/static/8e5b3f0e4b89c02f156f5edee1d533ba/50383/system-type-token.png",
            "srcSet": ["/static/8e5b3f0e4b89c02f156f5edee1d533ba/1efb2/system-type-token.png 370w", "/static/8e5b3f0e4b89c02f156f5edee1d533ba/50383/system-type-token.png 740w", "/static/8e5b3f0e4b89c02f156f5edee1d533ba/60b3a/system-type-token.png 1179w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Note: Figma Text Styles are used as type tokens. Color tokens are applied seperately.`}</em></p>
    <h3 {...{
      "id": "brand-tokens"
    }}>{`Brand Tokens`}</h3>
    <p>{`Brand tokens are a subset of system tokens. These tokens are used to set key brand colors and can be used by tenants to theme certain components to reflect their brand color. Brand tokens can be used to set key color categories like primary, success, and alert. Product teams will typically not need to use brand tokens unless they are setting tenant level theming or are creating new custom components that are meant to be themable.`}</p>
    <h4 {...{
      "id": "brand-color-token-example"
    }}>{`Brand Color Token Example`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cf28d15dbf73a67fec479d7a75ca093f/a4262/brand-token.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.162162162162165%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1UlEQVR42k2QS24CMRBEuQYrrsQZuAcsUM6UrHMIFEWIyY8ERBj/Zuixx25X2iYKaalcluu5JHuC3/E+gIgwDENVCEFOGf8nhBEkWeG8uPUeJhcm/zGTsuScYV2HVmkYa9Fqjdb0eDld0HwPcP56oet7aGMq58S/jENzJNBHB3Z8K6wlSkFpAyXwmxTdbzrM1ntMV+9YPlhgJJzO6spJmTk4+Kcez4tXPM632N8dboXMjJRSVYwRUZwCo2kTducITVyeIVmqWVGSPY8Mf5Rv+BwRdaqFPwNuMIzwdb+9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image of primary button example showing how brand tokens are applied.",
            "title": "Image of primary button example showing how brand tokens are applied.",
            "src": "/static/cf28d15dbf73a67fec479d7a75ca093f/50383/brand-token.png",
            "srcSet": ["/static/cf28d15dbf73a67fec479d7a75ca093f/1efb2/brand-token.png 370w", "/static/cf28d15dbf73a67fec479d7a75ca093f/50383/brand-token.png 740w", "/static/cf28d15dbf73a67fec479d7a75ca093f/a4262/brand-token.png 814w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "contribute-to-tokens"
      }}>{`Contribute To Tokens`}</h2>
      <p>{`Tokens are fundamental to the Canvas design system, so decisions to update or create additional
tokens is something which requires a great deal of consideration. If you have suggested refinements
or questions about the tokens in Canvas, reach out to the Canvas team on the
`}<a parentName="p" {...{
          "href": "https://workday-dev.slack.com/archives/C7AJWQ8BG"
        }}>{`#ask-canvas-design`}</a>{` or
`}<a parentName="p" {...{
          "href": "https://workday-dev.slack.com/archives/C8X0W5AQH"
        }}>{`#ask-canvas-kit`}</a>{` slack channels to discuss.`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      